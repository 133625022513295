<template>
	<BaseCard
			:title="data.title"
			:data-content-id="data._id"
			:data-content-key="data.key"
	>
		<PortableText
				:components="components"
				:value="data.body"
		/>
	</BaseCard>
</template>
<script setup lang="ts">
	import {PortableText} from '@portabletext/vue';
	import type {PropType} from 'vue';
	import BaseCard from '~/components/generic/BaseCard.vue';
	import type {SanityHelpPage} from '~/utils/helpPagesHelper';
	import SanityYoutubeEmbed from '~/components/html-pages/SanityYoutubeEmbed.vue';

	defineProps({
		data: {
			type: Object as PropType<SanityHelpPage>,
			required: true
		}
	});

	const components = {
		types: {
			youtube: SanityYoutubeEmbed
		}
	};
</script>
