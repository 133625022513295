import {acceptHMRUpdate, defineStore} from 'pinia';
import {navigateTo} from '#app';

export const useAuthStore = defineStore('auth', {
	state: () => ({
		authenticated: false,
		loading: true,
		loginUrl: null as string | null,
		token: null as string | null,
		loggedOut: true
	}),

	actions: {
		async login() {
			navigateTo(this.loginUrl, {external: true});
		},
		async logout() {
			this.authenticated = false;
			this.loggedOut = true;
			navigateTo('/');
		},
		setUnauthenticated() {
			this.authenticated = false;
			this.token = null;
			this.loading = false;
		},
		setAuthenticated(token: string) {
			this.token = token;
			this.authenticated = true;
			this.loggedOut = false;
			this.loading = false;
		},
		setLoginUrl(url: string) {
			this.loginUrl = url;
		}
	}
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
