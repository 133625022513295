import type {Middleware} from 'openapi-fetch';
import type {Ref} from 'vue';
import type {ProblemDetail} from '~/lib/types/fetchErrors';

export const authMiddleware = (authorizationHeader: Ref<string | null>): Middleware => ({
	onRequest: ({request}) => {
		if (authorizationHeader.value !== null) {
			request.headers.set('Authorization', authorizationHeader.value as string);
		}
		return request;
	}
});

const fallbackErrorTitle = 'Oeps';
const fallbackErrorMessage = 'Er is een onverwachte fout opgetreden.';

export const parseProblemDetail = (detail: ProblemDetail, formLabels: Record<string, string>): ProblemDetail => {
	if (!detail.fieldErrors) {
		return detail;
	}

	const mappedFieldErrors = Object.entries(detail.fieldErrors).map(([key, value]) => {
		const label = formLabels[key] || key;
		return [label, value];
	});
	detail.fieldErrors = Object.fromEntries(mappedFieldErrors);
	return detail;
};

export const errorHandlerMiddleware = (): Middleware => ({
	onResponse: async ({response}) => {
		if (response.status >= 400) {
			let responseBody;
			try {
				responseBody = await response.json();
			} catch (error) {
				// Add console.error for extra breadcrumb information in Sentry
				console.error(error);
				responseBody = {
					detail: fallbackErrorMessage,
					status: response.status,
					title: fallbackErrorTitle
				} satisfies ProblemDetail;
			}

			const problemDetail: ProblemDetail = {
				detail: responseBody.detail || fallbackErrorMessage,
				status: responseBody.status || response.status,
				title: responseBody.title || fallbackErrorTitle,
				fieldErrors: responseBody.fieldErrors
			};

			return new Response(
				JSON.stringify(problemDetail),
				{status: problemDetail.status}
			);
		}
		return response;
	}
});
