<template>
	<iframe
			:src="embedUrl"
			allowfullscreen
			height="315"
			loading="lazy"
			referrerpolicy="strict-origin-when-cross-origin"
			title="YouTube video player"
			width="560"
	></iframe>
</template>
<script lang="ts" setup>
	import getYouTubeID from 'get-youtube-id';
	import type {PortableTextComponentProps} from '@portabletext/vue';
	import {computed} from 'vue';

	const props = defineProps<
		PortableTextComponentProps<{
			url: string;
			default: '';
		}>
	>();
	const embedUrl = computed(() => `https://www.youtube-nocookie.com/embed/${getYouTubeID(props.value.url)}`);
</script>

<style scoped>
	iframe {
		max-width: 100%;
		height: auto;
		aspect-ratio: 16 / 9;
		border: none;
	}

	iframe:not(:first-child) {
		margin-top: 1rem;
	}

	iframe:not(:last-child) {
		margin-bottom: 1rem;
	}
</style>
