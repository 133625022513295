<template>
	<NuxtLayout>
		<NuxtPage />
		<DialogComponent />
		<UploadDialogComponent />
	</NuxtLayout>
</template>

<script lang="ts" setup>
	import {useHead, useNuxtApp, useRuntimeConfig} from '#app';
	import type {Router} from 'vue-router';
	import {h} from 'vue';
	import {initConfirmLeave} from '~/composables/useConfirmLeave';
	import {setupDialog} from '~/composables/useDialog';

	const nuxtApp = useNuxtApp();
	const config = useRuntimeConfig();

	initConfirmLeave(
		(beforeUnloadCallback) => window?.addEventListener('beforeunload', beforeUnloadCallback),
		(beforeRouteLeaveCallback) => (nuxtApp.$router as Router).beforeEach(beforeRouteLeaveCallback)
	);

	const dialogContext = setupDialog();
	const DialogComponent = () => h(dialogContext.Component);

	const uploadDialogContext = setupDialog('UploadDialog');
	const UploadDialogComponent = () => h(uploadDialogContext.Component);

	useHead({
		title: config.public.title,
		style: [
			{
				innerHTML: `
					@property --color-primary {
						syntax: '<color>';
						initial-value: ${config.public.theme.colorPrimary};
						inherits: true;
					}
					:root, html, body {
						--color-primary: ${config.public.theme.colorPrimary};
					}
				`
			}
		]
	});
</script>
