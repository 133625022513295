<template>
	<div
			:class="classes"
			:role="role"
			class="notification"
	>
		<div aria-hidden="true" class="notification-icon">
			<IconSvg :icon="icon" />
		</div>

		<h3 class="header">{{ title }}</h3>

		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type {HTMLAttributes, PropType} from 'vue';
	import {computed} from 'vue';
	import type {RouteLocationRaw} from 'vue-router';
	import type {AvailableIcon} from '~/lib/types/IconTypes';
	import IconSvg from '~/components/generic/IconSvg.vue';

	type NotificationType = 'info' | 'success' | 'warning' | 'error';

	const props = defineProps({
		title: {
			type: String,
			required: true
		},
		type: {
			type: String as PropType<NotificationType>,
			required: true
		},
		to: {
			type: [String, Object] as PropType<RouteLocationRaw>,
			default: null
		}
	});

	const classes = computed(() => {
		return [
			props.type
		];
	});

	const icon = computed((): AvailableIcon => {
		if (props.type === 'info') {
			return 'information';
		}

		if (props.type === 'success') {
			return 'check-circle';
		}

		return 'alert-circle';
	});

	const role = computed((): HTMLAttributes['role'] | undefined => {
		if (props.type === 'warning' || props.type === 'error') {
			return 'alert';
		}

		return undefined;
	});
</script>

<style scoped>
	.notification {
		--badge-bg: #ebebeb;
		--badge-color: #000000;
		--badge-icon-color: currentColor;

		background: var(--badge-bg);
		color: var(--badge-color);
		padding: 10px 16px;
		font-size: .8125rem;
		line-height: 1rem;
		text-decoration: none;
		font-weight: 400;
		display: grid;
		grid-template-columns:  auto 1fr auto;
		gap: 10px;
		margin: 0 0 var(--spacing-5);
	}

	:deep(svg) {
		--icon-size: 1rem;
		width: var(--icon-size);
		height: var(--icon-size);
		fill: var(--badge-icon-color);
	}

	.info {
		--badge-bg: var(--color-info-light);
		--badge-icon-color: var(--color-info);
	}

	.success {
		--badge-bg: var(--color-success-light);
		--badge-icon-color: var(--color-success);
	}

	.warning {
		--badge-bg: var(--color-warning-light);
		--badge-icon-color: var(--color-warning);
	}

	.error {
		--badge-bg: var(--color-error-light);
		--badge-icon-color: var(--color-error);
	}

	.header {
		font-size: var(--font-size-p-2);
		line-height: var(--line-height-p-2);
		font-weight: normal;
	}

	.content {
		font-size: var(--font-size-p-1);
		line-height: var(--line-height-p-1);
		grid-column: 2 / 4;
		grid-row: 2;
	}

	.content:empty {
		display: none;
	}
</style>
