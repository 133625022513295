import {type MaybeRef, onUnmounted, unref, watchEffect} from 'vue';

const element = document?.querySelector('html');
const scrollLockClassName = 'no-scroll';

const setScrollLock = (lock: boolean) => {
	element?.classList.toggle(scrollLockClassName, lock);
};

/**
 * Composable to set the scroll lock on the html element to prevent scrolling of the html page.
 * For example, when a modal or the off-canvas menu is opened, the 'background' should not be scrollable.
 * @param enabled
 */
export const useScrollLock = (enabled: MaybeRef<boolean>) => {
	watchEffect(() => {
		setScrollLock(unref(enabled));
	});

	/**
	 * Always remove the scroll lock when the component where used is unmounted
	 */
	onUnmounted(() => {
		setScrollLock(false);
	});
};
