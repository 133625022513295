<template>
	<div
			:class="{
				'sidebar-open': isSidebarOpen,
				'sidebar-expanded': isExpanded
			}"
			class="default-grid"
	>
		<div class="sidebar">
			<div class="sidebar-inner">
				<button
						class="menu-close"
						type="button"
						@click="toggleSidebar"
				>
					<span class="sr-only">Menu sluiten</span>
					<IconSvg icon="close" />
				</button>
				<slot name="sidebar"></slot>
			</div>
		</div>

		<div class="topbar">
			<button
					class="menu-hamburger"
					type="button"
					@click="toggleSidebar"
			>
				<IconSvg icon="hamburger" />
			</button>

			<div class="topbar-title desktop">
				<strong class="branche">{{ brancheName }}</strong>
				<span>Risico Inventarisatie & Evaluatie</span>
			</div>
			<div class="topbar-title mobile">
				{{ pageInfo?.mobileTitle ?? pageInfo?.title ?? 'RI&E' }}
			</div>
		</div>

		<div class="main">
			<div class="main-inner">
				<PageBreadcrumb v-if="!route.meta.hideBreadcrumb" :route="route" />
				<slot></slot>
			</div>
		</div>

		<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
		<div class="overlay" @click="isSidebarOpen = false; isExpanded = false;"></div>
	</div>
</template>

<script lang="ts" setup>
	import {computed, ref, watch} from 'vue';
	import {useRuntimeConfig} from '#app';
	import IconSvg from '~/components/generic/IconSvg.vue';
	import {useRoute} from '#imports';
	import PageBreadcrumb from '~/components/layout/PageBreadcrumb.vue';
	import type {PageInfo} from '~/lib/types/PageMeta';
	import {useScrollLock} from '~/composables/useScrollLock';

	const route = useRoute();

	const isSidebarOpen = ref(false);
	const isExpanded = ref(true);

	useScrollLock(isSidebarOpen);

	const toggleSidebar = () => {
		isSidebarOpen.value = !isSidebarOpen.value;
	};

	const brancheName = useRuntimeConfig().public.brancheName;

	const pageInfo = computed(() => {
		return route.meta.pageInfo as PageInfo | undefined;
	});

	watch(pageInfo, () => {
		isSidebarOpen.value = false;
	});

</script>

<style scoped>
	button {
		all: unset;
		padding: 22px 20px;
		--icon-size: 21px;
	}

	.branche {
		margin-right: .625rem;
	}

	@media screen and (min-width: 768px) {
		button {
			all: unset;
			padding: 13px;
			--icon-size: 13px;
		}
	}

	.default-grid {
		background: var(--background-color-main);
		display: grid;
		grid-template:
				"topbar topbar" minmax(40px, auto)
				"sidebar main" 1fr
				/ 0 1fr;
		min-height: 100vh;

		--display-menu-text: block;
		--sidebar-width: 240px;
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		z-index: 10;
		overscroll-behavior: contain;
	}

	.default-grid:not(.sidebar-open) .overlay {
		display: none;
	}

	.sidebar-open .sidebar-inner {
		width: var(--sidebar-width);
		height: 100%;
		background: #ffffff;
	}

	.sidebar {
		background: #ffffff;
		grid-area: sidebar;
		z-index: 11;
		overflow: visible;
		overscroll-behavior: contain;

		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
	}

	.sidebar-inner {
		position: sticky;
		top: 0;
		max-height: 100vh;
		overflow: auto;
		overscroll-behavior: contain;
	}

	.topbar {
		background: #ffffff;
		grid-area: topbar;
		border-bottom: 1px solid var(--color-grey-50);

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}

	.topbar-title {
		padding: 0 24px;
	}

	.topbar > * {
		flex: 0 0 auto;
	}

	.main {
		padding: 4px;
		grid-area: main;
		width: auto;
		overflow: auto;
	}

	@media screen and (max-width: 767px) {
		.default-grid:not(.sidebar-open) .sidebar {
			display: none;
		}

		.topbar-title.desktop {
			display: none;
		}

		.topbar-title.mobile {
			padding-left: 4px;
		}

	}

	@media screen and (min-width: 768px) {
		.default-grid {
			--sidebar-width: auto;
			grid-template:
				"topbar topbar" minmax(40px, auto)
				"sidebar main" 1fr
				/ var(--sidebar-width) 1fr;
		}

		.menu-hamburger {
			display: none;
		}

		.default-grid:not(.sidebar-open) .menu-close {
			display: none;
		}

		.sidebar {
			position: relative;
			--display-menu-text: none;
		}

		.sidebar-expanded .sidebar {
			--display-menu-text: block;
		}

		.main {
			padding: 20px;
		}

		.default-grid.sidebar-expanded {
			--sidebar-width: 240px;
		}

		.default-grid:not(.sidebar-expanded) .sidebar,
		.default-grid:not(.sidebar-expanded) .sidebar-inner {
			width: auto;
		}

		.sidebar-inner {
			max-height: calc(100vh - 40px);
		}

		.topbar-title.mobile {
			display: none;
		}
	}

	@media screen and (min-width: 1024px) {
		.default-grid {
			grid-template:
				"topbar topbar" minmax(40px, auto)
				"sidebar main" 1fr
				/ var(--sidebar-width) 1fr;
		}

		.menu-close {
			display: none;
		}

		.default-grid.sidebar-open .overlay {
			display: none;
		}
	}

	.main-inner {
		max-width: 920px;
		margin: 0 auto;
	}
</style>
