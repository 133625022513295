<template>
	<DefaultGrid>
		<template #sidebar>
			<SidebarLogo />
		</template>

		<template #default>
			<NotificationCard
					v-if="error?.statusCode === 404"
					title="404 - Pagina niet gevonden"
					type="error"
			>
				<p>Sorry, deze pagina bestaat niet</p>
			</NotificationCard>
			<NotificationCard
					v-else-if="error"
					:title="`${error.statusCode } - ${error.statusMessage}`"
					type="error"
			>
				<p>{{ error.message }}</p>
				<button @click="handleError">Clear errors</button>
			</NotificationCard>
		</template>
	</DefaultGrid>
</template>

<script setup lang="ts">
	import {clearError, useError} from '#app';
	import DefaultGrid from '~/components/layout/DefaultGrid.vue';
	import NotificationCard from '~/components/generic/NotificationCard.vue';
	import SidebarLogo from '~/components/layout/SidebarLogo.vue';

	const error = useError();

	const handleError = () => clearError({redirect: '/'});
</script>
