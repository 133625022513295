<template>
	<li :class="menuStyle">
		<LinkOrElement :to="to" class="menu-item" @click="handleClick">
			<span class="text">
				{{ text }}
			</span>

			<button
					v-if="subItems.length > 0"
					class="submenu-toggle"
					type="button"
					@click.stop="showSubmenu = !showSubmenu"
			>
				<IconSvg :icon="showSubmenu ? 'chevron-up' : 'chevron-down'" />
			</button>
		</LinkOrElement>

		<ul
				v-if="subItems.length > 0"
				v-show="showSubmenu"
		>
			<MenuItem
					v-for="item in subItems"
					:key="item.text"
					:to="item.to"
					:text="item.text"
					menu-style="submenu"
			/>
		</ul>
	</li>
</template>

<script setup lang="ts">
	import {type PropType, ref} from 'vue';
	import type {RouteLocationRaw} from 'vue-router';
	import type {MenuEntry} from '~/lib/types/MenuTypes';
	import LinkOrElement from '~/components/generic/LinkOrElement.vue';
	import IconSvg from '~/components/generic/IconSvg.vue';

	const props = defineProps({
		to: {
			type: [String, Object] as PropType<RouteLocationRaw>,
			default: undefined
		},
		text: {
			type: String,
			required: true
		},
		subItems: {
			type: Array as PropType<Array<MenuEntry>>,
			default: () => []
		},
		menuStyle: {
			type: String as PropType<'primary' | 'secondary' | 'submenu' | 'default'>,
			default: 'default'
		}
	});

	const showSubmenu = ref(true);

	const handleClick = (event: Event) => {
		if (props.subItems.length > 0) {
			event.preventDefault();
			showSubmenu.value = !showSubmenu.value;
		}
	};
</script>

<style scoped>
	li {
		list-style: none;
		font-size: var(--font-size-p-4);
		line-height: var(--line-height-p-4);
		flex: 0 0 auto;
	}

	.menu-item {
		color: inherit;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-items: start;
		padding: 11px 1rem 11px calc(24 / 16 * 1rem);
		--border-width: 4px;
		gap: 1rem;
	}

	li:has(ul .router-link-active) > .menu-item,
	.router-link-active {
		background: var(--color-primary-50);
		box-shadow: inset var(--border-width) 0 0 var(--color-primary-500);
	}

	.primary {
		font-weight: bold;
	}

	.submenu {
		font-size: var(--font-size-p-3);
		line-height: var(--line-height-p-3);

		.router-link-active {
			font-weight: 600;
			background: none;
			box-shadow: none;
		}
	}

	ul {
		padding: 0;
		margin: 0;
	}

	.submenu-toggle {
		background: none;
		border: none;
		margin-left: auto;
		cursor: pointer;
	}
</style>
