import type {ComponentPropsOptions, PropType} from 'vue';

export type InputTypes = 'text' | 'number' | 'date' | 'email' | 'password' | 'search';
type InputModeOptions = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';

export const sharedFormElementProps = {
	type: {
		type: String as PropType<InputTypes>,
		default: 'text'
	},
	modelValue: {
		type: [String, Number, Boolean, Array, Object],
		default: ''
	},
	id: {
		type: String,
		required: true
	},
	name: {
		type: String,
		required: true
	},
	readonly: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	}
} satisfies ComponentPropsOptions;

export const sharedFormInputProps = {
	modelValue: {
		type: String,
		default: ''
	},
	inputmode: {
		type: String as PropType<InputModeOptions>
	},
	placeholder: {
		type: String,
		default: ''
	},
	autocomplete: {
		type: String,
		default: 'off'
	},
	min: {
		type: Number,
		default: null
	},
	max: {
		type: Number,
		default: null
	},
	step: {
		type: Number,
		default: null
	}
};

export const formTextareaProps = {
	modelValue: {
		type: String,
		default: ''
	},
	rows: {
		type: Number,
		default: 3
	},
	columns: {
		type: Number,
		default: 48
	},
	spellcheck: {
		type: Boolean,
		default: true
	},
	nullIfEmpty: {
		type: Boolean,
		default: false
	},
	maxlength: {
		type: Number,
		default: null
	}
};

export const formRadioButtonsProps = {
	modelValue: {
		type: String,
		default: ''
	}
};

export const formSelectProps = {
	modelValue: {
		type: [String, Number],
		default: ''
	},
	options: {
		type: Array as PropType<{ label: string, value: string }[]>,
		required: true
	}
};
