import type {PortableTextBlock} from '@portabletext/types';
import {computed, type MaybeRef, unref} from 'vue';

type PageKeys = 'HOMEPAGE' | 'TOC_DECLINED' | 'HELP';

export interface SanityHelpPage {
	_id: string;
	page: string;
	key?: PageKeys | '',
	title: string;
	body: PortableTextBlock[];
}

export const mapHelpPageIdToKey = (page: string): PageKeys | '' => {
	if (page === 'HELP') {
		return 'HELP';
	}
	if (page === 'HOMEPAGE') {
		return 'HOMEPAGE';
	}

	return '';
};

export const useHomepageData = (data: MaybeRef<SanityHelpPage[] | null>) => {
	const homepageData = computed(() => {
		return unref(data)?.find((page) => page?.key === 'HOMEPAGE');
	});

	return {
		homepageData
	};
};

export const useTocDeclinedData = (data: MaybeRef<SanityHelpPage[] | null>) => {
	const pageData = computed(() => {
		return unref(data)?.find((page) => page?.key === 'TOC_DECLINED') ?? {
			'_id': 'fallback-toc-declined',
			'page': 'TOC_DECLINED',
			'title': 'Geen toegang',
			'body': [
				{
					'_type': 'block',
					'children': [
						{
							'_type': 'span',
							'text': 'Je hebt de algemene voorwaarden afgewezen en kunt geen gebruik maken van de RI&E'
						}
					]
				}
			]
		} satisfies SanityHelpPage;
	});

	return {
		pageData
	};
};

export const useHelpPageData = (data: MaybeRef<SanityHelpPage[] | null>) => {
	const pageData = computed(() => {
		return unref(data)?.find((page) => page?.key === 'HELP') ?? {
			'_id': 'fallback-help-page',
			'page': 'HELP',
			'title': 'Help',
			'body': [
				{
					'_type': 'block',
					'children': [
						{
							'_type': 'span',
							'text': 'Tekst kan momenteel niet worden geladen, probeer het later opnieuw.'
						}
					]
				}
			]
		} satisfies SanityHelpPage;
	});

	return {
		pageData
	};
};
