import {useRuntimeConfig} from '#app';

export class SanityImage extends HTMLElement {
	jpgImageUrl: string | undefined;
	webpImageUrl: string | undefined;
	height: string = '200';

	constructor() {
		super();
		this.attachShadow({mode: 'open'});
	}

	static observedAttributes = ['data-src', 'data-height'];

	connectedCallback(): void {
		this.render();
	}

	attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
		if ((name === 'data-src' || name === 'data-height') && oldValue !== newValue) {
			this.loadImage();
		}
	}

	loadImage(): void {
		const dataSrc = this.getAttribute('data-src');
		this.height = this.getAttribute('data-height') ?? this.height;
		if (dataSrc) {
			this.fetchImageAndDisplay(dataSrc, this.height)
				.catch(error => console.error('Error processing image:', error));
		}
	}

	async fetchImageAndDisplay(dataSrc: string, height?: string): Promise<void> {
		const sanityConfig = useRuntimeConfig().public.sanity;
		const metadataResponse = await fetch(`https://${sanityConfig.projectId}.api.sanity.io/v2021-06-07/data/query/${sanityConfig.dataset}?query=*[_id==$id]&$id="${dataSrc}"`);
		const metadata = await metadataResponse.json();
		this.webpImageUrl = `${metadata?.result?.[0]?.url}?fm=webp&h=${height || '200'}`;
		this.jpgImageUrl = `${metadata?.result?.[0]?.url}?fm=jpg&h=${height || '200'}`;
		this.render();
	}

	css() {
		return `
			<style>
				:host {
				    display: flex;
					height:${this.height}px; 
					max-width:${this.height}px;
				}
				
				picture {
					display: flex;
				}
			
				.sanity-image-loading, picture img { 
					animation: load 2s infinite linear;
					max-width:${this.height}px;
					height:${this.height}px;
			    }
			    
				@keyframes load { 
					0% { background: #fafafa; } 
					50% { background: #dde9e9; } 
					100% { background: #fafafa; } 
				}
			</style>
		`;
	}

	render(): void {
		if (this.shadowRoot) {
			if (!this.webpImageUrl && !this.jpgImageUrl) {
				this.shadowRoot.innerHTML = `
				<div class="sanity-image-loading"></div>
				${this.css()}
			`;
			} else {
				this.shadowRoot.innerHTML = `
				<picture>
				  <source srcset="${this.webpImageUrl}" type="image/webp" media="screen">
				  <source srcset="${this.jpgImageUrl}" media="print">
				  <img src="${this.jpgImageUrl}" alt="">
				</picture>
				${this.css()}`;
			}
		}
	}
}
