<template>
	<HomePageCard
			v-if="homepageData"
			:data="homepageData"
	/>

	<ButtonBar v-if="!store.authenticated">
		<LoginButton
				:loading="store.loading"
				:authenticated="store.authenticated"
				@login="store.login()"
		/>
	</ButtonBar>
</template>
<script setup lang="ts">
	import {useFetch} from '#app';
	import {definePageMeta} from '#imports';
	import {pageNames} from '~/lib/pageHelper';
	import HomePageCard from '~/components/html-pages/HomePageCard.server.vue';
	import {useAuthStore} from '~/store/global/auth';
	import ButtonBar from '~/components/generic/buttons/ButtonBar.vue';
	import LoginButton from '~/components/generic/LoginButton.vue';
	import {useHomepageData} from '~/utils/helpPagesHelper';

	const store = useAuthStore();

	const {data} = await useFetch('/sanity-help-pages', {server: true, key: 'sanity-help-pages'});

	// @ts-expect-error - Cannot get the typing right for the compiler, but it works?
	const {homepageData} = useHomepageData(data);

	definePageMeta({
		name: pageNames.index,
		allowSSR: true,
		pageInfo: {
			title: 'Branche RIE',
			mobileTitle: 'Branche RIE'
		},
		hideBreadcrumb: true
	});
</script>
