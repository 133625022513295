import payload_plugin_PsI6g7yQdp from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_0zETQoLw63 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GAoH8dkFQo from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_75u23r3Qjf from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_RmYWZQMpQ0 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nlGx2kRmBZ from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cU8jrNmeV0 from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_A2ZoSeQWCm from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xpCNpdAW3U from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.2.8_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.27.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_OI77UwAxnM from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@20.17.12_eslint@8.57.1_ioredis@5.4.1_magicas_csrcaonsl7dfqoh4folv4gd3em/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import customElements_client_71mjTDa34z from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/customElements.client.ts";
import sentry_client_shVUlIjFLk from "/var/lib/jenkins/workspace/branche-rie-build-stigas_master/frontend/plugins/sentry.client.ts";
export default [
  payload_plugin_PsI6g7yQdp,
  revive_payload_client_0zETQoLw63,
  unhead_GAoH8dkFQo,
  router_75u23r3Qjf,
  payload_client_RmYWZQMpQ0,
  navigation_repaint_client_nlGx2kRmBZ,
  check_outdated_build_client_cU8jrNmeV0,
  chunk_reload_client_A2ZoSeQWCm,
  plugin_vue3_xpCNpdAW3U,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OI77UwAxnM,
  customElements_client_71mjTDa34z,
  sentry_client_shVUlIjFLk
]