<script setup lang="ts">
	import {computed} from 'vue';
	import {type RouteLocationRaw, useRoute} from 'vue-router';
	import IconSvg from '~/components/generic/IconSvg.vue';

	const props = defineProps<{
		showBacklink: boolean,
		backRoute?: RouteLocationRaw,
		title: string,
		subtitle?: string
		compact?: boolean
	}>();

	const route = useRoute();

	const backRoute = computed(() => {
		if (!props.showBacklink) {
			return undefined;
		}

		if (props.backRoute) {
			return props.backRoute;
		}

		return route.path.endsWith('/') ? '../' : './';
	});
</script>

<template>
	<nav>
		<NuxtLink
				v-if="showBacklink"
				:to="backRoute"
				class="backlink"
		>
			<span class="sr-only">Terug</span>
			<IconSvg icon="arrow-left" />
		</NuxtLink>
		<div v-else class="backlink placeholder">
			<IconSvg icon="arrow-left" />
		</div>
		<div class="title">
			{{ title }}
		</div>
		<div v-if="!compact && subtitle" class="subtitle">
			{{ subtitle }}
		</div>
	</nav>
</template>

<style scoped>
	@media screen and (max-width: 767px) {
		nav {
			display: none;
		}
	}

	nav {
		margin: 0 0 var(--spacing-5);
	}

	.placeholder {
		visibility: hidden;
	}

	.backlink {
		display: inline-block;
		color: currentColor;
		margin: 0 0 8px;
	}

	.title {
		/* HEADER 5; */
		font-size: 1.25rem;
		line-height: 1.2;
		font-weight: bold;
	}

	.subtitle {
		font-size: var(--font-size-p-2);
		line-height: var(--line-height-p-2);
	}
</style>
