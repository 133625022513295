import * as Sentry from '@sentry/vue';
import {defineNuxtPlugin, useRouter, useRuntimeConfig} from '#app';

export default defineNuxtPlugin((nuxtApp) => {
	const router = useRouter();
	const sentry = useRuntimeConfig().public.sentry;

	if (!sentry.dsn) {
		return;
	}

	Sentry.init({
		app: nuxtApp.vueApp,
		dsn: sentry.dsn,
		environment: sentry.environment,
		integrations: [
			Sentry.browserTracingIntegration({
				router
			})
		],
		tracesSampleRate: 1,
		tracePropagationTargets: ['localhost:3000', 'localhost:8080', /^\/api\//],
		enableTracing: true
	});
});
