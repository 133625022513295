/**
 * Composable to use a confirm dialog
 * @param message - The message to show in the confirm dialog
 * @param onConfirm - The function to call when the user confirms
 * @param onCancel - The function to call when the user cancels, if not provided, the promise will be rejected
 */
export const useConfirm = (message: string, onConfirm: () => void, onCancel?: () => void) => new Promise<void | Error>((resolve, reject) => {
	// eslint-disable-next-line no-alert
	const confirmed = window.confirm(message);
	if (confirmed) {
		onConfirm();
		resolve();
		return;
	}
	if (typeof onCancel === 'function') {
		onCancel();
		resolve();
		return;
	}
	reject(new Error('No onCancel function provided'));
});
