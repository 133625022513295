import {
	type AllowedComponentProps,
	type Component,
	type MaybeRef,
	type VNodeProps,
	h,
	inject,
	markRaw,
	provide,
	ref,
	shallowRef,
	onUnmounted
} from 'vue';
import {useScrollLock} from '~/composables/useScrollLock';

/**
 * The context id for the dialog.
 * This is used to identify the dialog context if it is needed to have more than one dialog open at the same time.
 * If more dialogContextIds need to be added: 'UploadDialog' | 'AnotherDialog' | '...'
 */
export type DialogContextId = 'UploadDialog'

const createContext = (id?: DialogContextId) => {
	const visible = ref(false);
	useScrollLock(visible);

	const currentComponent = ref<Component | null>(null);
	const currentProps = ref<any>(null);
	const dialogRef = shallowRef<HTMLDialogElement | null>(null);

	return {
		isVisible: () => dialogRef.value?.open,
		async setComponent(component: Component, props: any) {
			currentComponent.value = markRaw(component);
			currentProps.value = props;
		},
		show: () => {
			visible.value = true;
			dialogRef.value?.showModal();
		},
		hide: () => {
			dialogRef.value?.close();

			visible.value = false;
		},
		Component: () => {
			return h('dialog', {
				ref: dialogRef,
				class: 'use-dialog',
				dataDialogContextId: id
			}, currentComponent.value && visible.value ? h(currentComponent.value, currentProps.value) : undefined);
		}
	};
};

export const setupDialog = (id?: DialogContextId) => {
	const context = createContext(id);
	provide(`dialogContext${id}`, context);
	return context;
};

export const getDialogContext = (id?: DialogContextId) => {
	const dialogContext = inject(`dialogContext${id}`);

	if (!dialogContext) {
		throw new Error(`useDialog must be used within a initialized tree, please call setupDialog(${id ? `"${id}"` : ''}) in the root component`);
	}

	return dialogContext as ReturnType<typeof createContext>;
};

type ToMaybeRefs<T = any> = {
	[K in keyof T]: MaybeRef<T[K]>;
};

// eslint-disable-next-line no-unused-vars
export type ComponentProps<C extends Component> = C extends new (...args: any[]) => any
	? ToMaybeRefs<Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps>>
	: Record<string, any>;

export const useDialog = <C extends Component>(component: C, props: ComponentProps<C>, id?: DialogContextId) => {
	const dialogContext = getDialogContext(id);

	onUnmounted(() => {
		useScrollLock(false);
	});

	return {
		open: () => {
			dialogContext.setComponent(component, props);
			dialogContext.show();
		},
		close: () => {
			dialogContext.hide();
		}
	};
};
