<template>
	<BreadcrumbHeader
			:title="title"
			:subtitle="pageInfo?.subTitle"
			:show-backlink="showBacklink"
			:compact="compact"
			:back-route="backRoute"
	/>
</template>
<script lang="ts" setup>
	import type {PropType} from 'vue';
	import {computed} from 'vue';
	import type {RouteLocationNormalizedLoaded} from 'vue-router';
	import type {PageInfo} from '~/lib/types/PageMeta';
	import BreadcrumbHeader from '~/components/generic/BreadcrumbHeader.vue';

	const props = defineProps({
		route: {
			type: Object as PropType<RouteLocationNormalizedLoaded>,
			required: true
		},
		compact: {
			type: Boolean,
			default: false
		}
	});

	const pageInfo = computed(() => {
		return props.route.meta.pageInfo as PageInfo | undefined;
	});

	const showBacklink = computed(() => {
		return pageInfo.value?.showBacklink ?? false;
	});

	const title = computed(() => {
		if (props.compact && pageInfo.value?.mobileTitle) {
			return pageInfo.value.mobileTitle;
		}
		return pageInfo.value?.title ?? 'RI&E';
	});

	const backRoute = computed(() => {
		if (!showBacklink.value) {
			return undefined;
		}

		return props.route.path.endsWith('/') ? '../' : './';
	});
</script>
